import React from 'react';
import { To, useLocation, useNavigate, useParams } from 'react-router';

import {
  GeoMessage,
  ITrackingProviderId,
  TrackingProvider,
  TrackingProviderId,
  TrackSummary
} from '@wildidea/onemap-tracking';

import { UserTrackOnMap } from '@data/TrackOnMap.js';
import { usePropRef } from '@wildidea/components/hooks/usePropRef';
import { useSearchParams } from 'react-router-dom';

export interface NavigationProps {
  to: To;
  state: Record<string, unknown> | null | undefined;
}

// About Route
export const ABOUT_ROUTE_SLUG = '/about';

// Explore Scene
export const EXPLORE_SCENE_SLUG = '/explore';
export const useGetExploreNavigationProps = () => {
  const location = useLocation();
  const locationRef = usePropRef(location);
  return React.useRef(function getExploreNavigationProps() {
    return {
      to: `/explore${locationRef.current.search}`,
      state: null
    } satisfies NavigationProps;
  }).current;
};
export const useExploreNavigationProps = () => {
  const getExploreNavigationProps = useGetExploreNavigationProps();
  return getExploreNavigationProps();
};
export const useClearFocus = () => {
  const getExploreNavigationProps = useGetExploreNavigationProps();
  const navigate = useNavigate();
  return React.useRef(function clearFocus() {
    const { to, state } = getExploreNavigationProps();
    navigate(to, { state });
  }).current;
};

// User Scene
export const USER_SCENE_SLUG = '/user/:userId';
export const useFocusedUserId = () => useParams()['userId'] || null;
export const useGetUserNavigationProps = () => {
  const location = useLocation();
  const locationRef = usePropRef(location);
  return React.useRef(function getUserNavigationProps(userId: string) {
    return {
      to: `/user/${userId}${locationRef.current.search}`,
      state: locationRef.current.state
    } satisfies NavigationProps;
  }).current;
};
export const useUserNavigationProps = <UserId extends string | undefined>(
  userId: UserId
): UserId extends string ? NavigationProps : undefined => {
  const getUserNavigationProps = useGetUserNavigationProps();
  return (userId ? getUserNavigationProps(userId) : undefined) as UserId extends string
    ? NavigationProps
    : undefined;
};
export const useFocusUser = () => {
  const getUserNavigationProps = useGetUserNavigationProps();
  const navigate = useNavigate();
  return React.useRef(function focusTrack(userId: string) {
    const { to, state } = getUserNavigationProps(userId);
    navigate(to, { state });
  }).current;
};

// User Track Scene
export type TrackNavigationInput =
  | UserTrackOnMap
  | TrackSummary
  | { id: string; userId: ITrackingProviderId };
export const USER_TRACK_SCENE_SLUG = '/user/:userId/track/:trackId';
export const useFocusedTrackId = () => useParams()['trackId'] || null;
export const useGetTrackNavigationProps = () => {
  const location = useLocation();
  const locationRef = usePropRef(location);
  return React.useRef(function getTrackNavigationProps(track: TrackNavigationInput) {
    return {
      to: `/user/${'details' in track ? track.details.user.id.id : 'user' in track ? track.user.id.id : track.userId.id}/track/${track.id}${locationRef.current.search}`,
      state: locationRef.current.state
    } satisfies NavigationProps;
  }).current;
};
export const useTrackNavigationProps = <Track extends TrackNavigationInput | undefined>(
  track: Track
): Track extends TrackNavigationInput ? NavigationProps : undefined => {
  const getTrackNavigationProps = useGetTrackNavigationProps();
  return (track && getTrackNavigationProps(track)) as Track extends TrackNavigationInput
    ? NavigationProps
    : undefined;
};
export const useFocusTrack = () => {
  const getTrackNavigationProps = useGetTrackNavigationProps();
  const navigate = useNavigate();
  return React.useRef(function focusTrack(track: TrackNavigationInput) {
    const { to, state } = getTrackNavigationProps(track);
    navigate(to, { state });
  }).current;
};

// User Message Scene
export type MessageNavigationInput =
  | Pick<GeoMessage, 'user' | 'id'>
  | { id: ITrackingProviderId; userId: ITrackingProviderId };
export const USER_MESSSAGE_SCENE_SLUG = '/user/:userId/message/:messageId';
export const useFocusedMessageId = () => useParams()['messageId'] || null;
export const useGetMessageNavigationProps = () => {
  const location = useLocation();
  const locationRef = usePropRef(location);
  return React.useRef(function getTrackNavigationProps(message: MessageNavigationInput) {
    return {
      to: `/user/${'userId' in message ? message.userId.id : message.user.id.id}/message/${new TrackingProviderId(message.id).toString()}${locationRef.current.search}`,
      state: locationRef.current.state
    } satisfies NavigationProps;
  }).current;
};
export const useMessageNavigationProps = <Message extends MessageNavigationInput | undefined>(
  message: Message
): Message extends MessageNavigationInput ? NavigationProps : undefined => {
  const getMessageNagivationProps = useGetMessageNavigationProps();
  return (message && getMessageNagivationProps(message)) as Message extends MessageNavigationInput
    ? NavigationProps
    : undefined;
};
export const useFocusMessage = () => {
  const getMessageNagivationProps = useGetMessageNavigationProps();
  const navigate = useNavigate();
  return React.useRef(function focusMessage(message: MessageNavigationInput) {
    const { to, state } = getMessageNagivationProps(message);
    navigate(to, { state });
  }).current;
};

export const includeState = (navigationProps: NavigationProps, state: Record<string, unknown>) => ({
  ...navigationProps,
  state: { ...navigationProps.state, ...state }
});
export const excludeState = (
  navigationProps: NavigationProps | undefined,
  ...stateKeys: string[]
) => {
  if (navigationProps?.state) {
    return {
      ...navigationProps,
      state: Object.fromEntries(
        Object.entries(navigationProps.state).filter(([key]) => !stateKeys.includes(key))
      )
    };
  } else {
    return navigationProps;
  }
};

// Tracked Users (search params)
export const useTrackedUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const locationRef = usePropRef(location);
  const trackedUsers = React.useMemo(() => getTrackedUsers(searchParams), [searchParams]);

  const setParamsRef = usePropRef(setSearchParams);
  const setTrackedUsers = React.useRef(function setTrackedUsers(
    value: ITrackingProviderId[] | ((currentValue: TrackingProviderId[]) => ITrackingProviderId[])
  ) {
    setParamsRef.current(
      function urlSearchParamsUpdaterForTrackedUsers(currentSearchParams) {
        const nextValue =
          typeof value === 'function' ? value(getTrackedUsers(currentSearchParams)) : value;

        const serviceUserCSVLists = nextValue.reduce(
          (byService, nextId) => {
            byService[nextId.provider] = byService[nextId.provider] || new Set<string>();
            byService[nextId.provider]!.add(nextId.id);
            return byService;
          },
          {} as Partial<Record<TrackingProvider, Set<string>>>
        );
        return {
          ...currentSearchParams,
          ...Object.fromEntries(
            Object.entries(serviceUserCSVLists).map(([service, ids]) => [
              service,
              Array.from(ids).join(',')
            ])
          )
        };
      },
      { state: locationRef.current.state }
    );
  }).current;

  const startTrackingUser = React.useRef(function startTrackingUser(id: ITrackingProviderId) {
    setTrackedUsers((trackedUsers) => [...trackedUsers, id]);
  }).current;
  const stopTrackingUser = React.useRef(function stopTrackingUser(user: ITrackingProviderId) {
    setParamsRef.current(
      (currentParams) => {
        const newParams = new URLSearchParams(currentParams);
        const newValue = (newParams.get(user.provider) || '')
          .split(',')
          .filter((id) => id !== user.id)
          .join(',');
        if (newValue) {
          newParams.set(user.provider, newValue);
        } else {
          newParams.delete(user.provider);
        }
        return newParams;
      },
      { state: locationRef.current.state }
    );
  }).current;

  return {
    trackedUsers,
    startTrackingUser,
    stopTrackingUser
  };
};

export const getTrackedUsers = (searchParams: URLSearchParams): TrackingProviderId[] => {
  return Object.values(TrackingProvider).reduce<TrackingProviderId[]>((allUsers, nextService) => {
    const serviceUsers = searchParams.get(nextService)?.split(',');
    if (serviceUsers) {
      return allUsers.concat(
        serviceUsers.map(
          (serviceUser) => new TrackingProviderId({ provider: nextService, id: serviceUser })
        )
      );
    }
    return allUsers;
  }, []);
};
