import React from 'react';
import { Navigate, Route, useLocation, useNavigate } from 'react-router';

import { AnimatedRoute, AnimatedRoutes } from '@wildidea/components/AnimatedRoutes/AnimatedRoutes';
import { useBodyClass } from '@wildidea/components/hooks/useBodyClass';
import { isTouchOnlyDevice } from '@wildidea/components/utils/isTouchOnlyDevice';

const MapRoute = React.lazy(
  () => import('./routes/MapHostViewport.js' /** webpackPrefetch true */)
);
const ExploreScene = React.lazy(
  () => import('./routes/explore/ExploreScene.js' /** webpackPrefetch true */)
);
const UserScene = React.lazy(
  () => import('./routes/user/UserScene.js' /** webpackPrefetch true */)
);
const UserMessageScene = React.lazy(
  () => import('./routes/user/UserMessageScene.js' /** webpackPrefetch true */)
);
const UserTrackScene = React.lazy(
  () => import('./routes/user/UserTrackScene.js' /** webpackPrefetch true */)
);
const AboutRoute = React.lazy(
  () => import('./routes/about/AboutRoute.js' /** webpackPrefetch true */)
);
import OverlayViewport from './routes/OverlayViewport.js';

import './viewport.css';
import {
  ABOUT_ROUTE_SLUG,
  EXPLORE_SCENE_SLUG,
  USER_MESSSAGE_SCENE_SLUG,
  USER_SCENE_SLUG,
  USER_TRACK_SCENE_SLUG
} from './navigation.js';
import { useStorageBackedRef } from '@wildidea/components/hooks/useStorageBackedState';

export const App: React.FC<{}> = () => {
  useBodyClass(isTouchOnlyDevice() ? 'touch-only' : null);

  // const [loginModalOpen, setLoginModalOpen] = useParameterDialogState('login');
  // const [params] = useSearchParams();
  // const defaultUsername = params.get('user');

  const hasVisitedBefore = useStorageBackedRef({
    storageKey: VISITED_BEFORE_STORAGE_KEY,
    defaultValue: false
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (pathname === EXPLORE_SCENE_SLUG && hasVisitedBefore.current === false) {
      navigate(ABOUT_ROUTE_SLUG, { replace: true });
    }
    hasVisitedBefore.current = true;
  }, []);

  console.log('hasVisitedBefore', hasVisitedBefore);

  return (
    <>
      {/* <header className="header">
        <Logo />
        <TopNav />
      </header> */}

      <main>
        <AnimatedRoutes>
          <AnimatedRoute
            key="map-host"
            element={
              <React.Suspense>
                <MapRoute />
              </React.Suspense>
            }
          >
            {/* Explore */}
            <AnimatedRoute
              key="explore"
              path={EXPLORE_SCENE_SLUG}
              element={
                <React.Suspense>
                  <ExploreScene />
                </React.Suspense>
              }
            />

            {/* User */}
            <AnimatedRoute
              key="user"
              path={USER_SCENE_SLUG}
              element={
                <React.Suspense>
                  <UserScene />
                </React.Suspense>
              }
            >
              <AnimatedRoute
                key="user-message"
                path={USER_MESSSAGE_SCENE_SLUG}
                element={
                  <React.Suspense>
                    <UserMessageScene />
                  </React.Suspense>
                }
              />
              <AnimatedRoute
                key="user-track"
                path={USER_TRACK_SCENE_SLUG}
                element={
                  <React.Suspense>
                    <UserTrackScene />
                  </React.Suspense>
                }
              />
            </AnimatedRoute>

            {/* Trip */}
            {/* <AnimatedRoute
              key="trip"
              path="trip/:tripId"
              element={
                <React.Suspense>
                  <TripScene />
                </React.Suspense>
              }
            >
              <AnimatedRoute
                key="trip-user"
                path="user/:userId"
                element={
                  <React.Suspense>
                    <TripUserScene />
                  </React.Suspense>
                }
              />
              <AnimatedRoute
                key="trip-track"
                path="track/:trackId"
                element={
                  <React.Suspense>
                    <TripTrackScene />
                  </React.Suspense>
                }
              >
                <AnimatedRoute
                  key="trip-track-point"
                  path="point/:pointId"
                  element={
                    <React.Suspense>
                      <TripTrackPointScene />
                    </React.Suspense>
                  }
                />
              </AnimatedRoute>
            </AnimatedRoute> */}

            {/* Map-covering overlays */}
            <AnimatedRoute key="overlay" element={<OverlayViewport />}>
              <AnimatedRoute
                key="about"
                path="about"
                element={
                  <React.Suspense>
                    <AboutRoute />
                  </React.Suspense>
                }
              />
            </AnimatedRoute>
          </AnimatedRoute>

          <Route
            path="*"
            element={
              <Navigate
                to={!hasVisitedBefore.current ? ABOUT_ROUTE_SLUG : EXPLORE_SCENE_SLUG}
                replace
              />
            }
          />
        </AnimatedRoutes>
      </main>

      {/* <LoginModal
        open={loginModalOpen}
        defaultUsername={defaultUsername || undefined}
        onClose={() => {
          setLoginModalOpen(false);
        }}
      /> */}
    </>
  );
};

const VISITED_BEFORE_STORAGE_KEY = 'App/visited';
